import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ApiSidebar from "../../components/api-sidebar"
import ApiDocFooter from "../../components/api-doc-footer"

const ApiListSoundsPage = () => (
  <Layout title="List Sounds API" sidebar={ApiSidebar()}>
    <SEO title="List Sounds API" />
    <p>...</p>

    <ApiDocFooter active="/api/list-sounds/" />
  </Layout>
)

export default ApiListSoundsPage
